<script>
import pianificazioneAttivita from "@/components/widgets/tasks/pianificazioneAttivita";
import Swal from "sweetalert2";
import axios from 'axios';
import serverConfig from '@/helpers/config';
import moment from 'moment'

export default {
  name:'ganttWidget',
  props:[
    "type",
    "resourceID",
    "data",
  ],
  components: {
    pianificazioneAttivita,
  },
  setup() {
    let tk = 'Bearer '+localStorage.getItem('tk')
    return { tk };
  },
  data( ){
    return {
      UrlServer: serverConfig.EP,
      datastore: this.$store.state,
      page: 1,
      perPage: 1000,
      sortRes:'asc',
      value: 'All',
      pages: [],
      responseList: [],
      paginated:{},
      select:'all',
      search:'',
      chartStart: localStorage.getItem('chart-start'),
      chartEnd: localStorage.getItem('chart-end'),
      start:moment().format('YYYY-MM-DD HH:mm'),
      end:new Date().getFullYear().toString() + '-12-31 23:59',
      minDate:moment().format('YYYY-MM-DDTHH:mm'),
      maxDate:new Date().getFullYear().toString() + '-12-31T23:59',
      minDateEnd:moment().format('YYYY-MM-DDTHH:mm'),
      maxDateEnd:new Date().getFullYear().toString() + '-12-31T23:59',
      precision:'month',
      row1BarList:[
            {
            myBeginDate: "2024-05-05 13:00",
            myEndDate: "2024-05-13 19:00",
            ganttBarConfig: {
                // each bar must have a nested ganttBarConfig object ...
                id: "unique-id-1", // ... and a unique "id" property
                label: "Lorem ipsum dolor"
            }
            },
            {
            myBeginDate: "2024-05-15 13:00",
            myEndDate: "2024-05-23 19:00",
            ganttBarConfig: {
                // each bar must have a nested ganttBarConfig object ...
                id: "unique-id-2", // ... and a unique "id" property
                label: "Lorem ipsum dolor"
            }
            }
        ],
        row2BarList :[
        {
        myBeginDate: "2024-05-13 00:00",
        myEndDate: "2024-05-14 02:00",
        ganttBarConfig: {
            id: "another-unique-id-2",
            hasHandles: true,
            label: "Hey, look at me",
            style: {
            // arbitrary CSS styling for your bar
            background: "#e09b69",
            borderRadius: "20px",
            color: "black"
            },
            class: "foo" // you can also add CSS classes to your bars!
        }
        }
        ]
    }
  },
  mounted(){
    this.getList()
  },
  methods:{
    axiosInterceptor(){
      axios.interceptors.response.use(
        (response) => {
            
            return response;
        },
        (error) => {
            if (error.response && error.response.status === 401) {
              Swal.fire({
                title:"Accesso non autorizzato",
                text:"Mi spiace, ma l'accesso a questa risorsa non è autorizzato, probabilmente a causa della sessione scaduta o per mancanza dei requisiti",
                icon:"warning",
                showCancelButton:false,
                showConfirmButton:false,
                timer:2000,
              })
                this.$router.push('/login')
                if(localStorage.getItem('tk')){
                  localStorage.removeItem('tk')
                }
                return Promise.reject('Unauthorized access');
            } else if (error.response && error.response.status  === 404) {
                Swal.fire({
                  title:"Nessun risultato trovato",
                  text:"Mi spiace, nessun risultato trovato",
                  icon:"error",
                  showCancelButton:false,
                  showConfirmButton:false,
                  timer:2000,
                })
                return Promise.reject('The server cannot find the requested resource');
            }  else if (error.response && error.response.status  === 412) {
              Swal.fire({
                title:"Credenziali mancanti",
                text:"Inserisci delle credenziali valide, per favore.",
                icon:"warning",
                showCancelButton:false,
                showConfirmButton:false,
                timer:2000,
              })
              return Promise.reject('The server cannot find the requested resource');
          } else if (error.response && error.response.status  === 423) {
                Swal.fire({
                  title:"Risorsa non accessibile",
                  text:"Il contenuto al quale stai tentando di accedere non è accessibile.",
                  icon:"error",
                  showCancelButton:false,
                  showConfirmButton:false,
                  timer:2000,
                })
                this.$router.push('/')   
                return Promise.reject('The server cannot find the requested resource');
            }
            else if (error.response && error.response.status  === 500) {
              Swal.fire({
                title:"Errore Server",
                text:"Mi spiace, ma riscontriamo degli errori sul server.",
                icon:"error",
                showCancelButton:false,
                showConfirmButton:false,
                timer:2000,
              })
                return Promise.reject('Internal Server Error');
            }
            else if (error.response && error.response.status  === 502) {
                Swal.fire({
                  title:"Errore Server",
                  text:"Mi spiace, ma riscontriamo degli errori sul server.",
                  icon:"error",
                  showCancelButton:false,
                  showConfirmButton:false,
                  timer:2000,
                })
                return Promise.reject('Bad Gateway');
            }
            else if (error.response && error.response.status  === 503) {
              Swal.fire({
                title:"Servizio non disponibile",
                text:"Mi spiace, il servizio richiesto è momentaneamente indisponibile.",
                icon:"error",
                showCancelButton:false,
                showConfirmButton:false,
                timer:2000,
              })
                return Promise.reject('Service Unavailable');
            } else {
              console.log(error.response.status )
              Swal.fire({
                title:"Errore imprevisto",
                text:"Ops! si è verificato un problema...riprova, per favore.",
                icon:"error",
                showCancelButton:false,
                showConfirmButton:false,
                timer:2000,
              })
              
            }

        }
    );      
    },
    updateDate(type){
      if (type=='start'){
        let cstart = moment(this.start).format('YYYY-MM-DD HH:mm')
        localStorage.setItem('chart-start',cstart)
      } else {
        let cend = moment(this.start).format('YYYY-MM-DD HH:mm')
        localStorage.setItem('chart-end',cend)
      }
      location.reload()
    },
    getParent(){
      this.getList()
    },
    getList(){
      this.axiosInterceptor()
      axios.get(`${this.UrlServer}tasks/advfilter/category`, 
        { 
          params: { 
            type: 'task-gantt',
            resourceID: this.$route.params.id,
            perPage: this.perPage,
            page: this.page,
            sortRes: this.sortRes,
            select: this.select,
            search: this.search,
          }, 
          headers: { 'authorization':this.tk}
        }
      ).then(response=>{
        this.responseList = response.data.results.data;
        this.paginated = response.data.results
      })
    },
    onDrop(event, datetime) {
    alert(JSON.stringify(event))
    console.log(datetime)
   },
   onMouseupBar(bar, e,datetime){
    alert(JSON.stringify(bar))
    console.log(e)
    console.log(datetime)
   },

  }


}
</script>
<template>
    <div>
        <template v-if="resourceID">
            <pianificazioneAttivita :projectID="resourceID" :worksiteID="resourceID" :resourceID="resourceID" type="task-gantt" @getParent=getParent() />
        </template>
        <div class="row background-btn-grey-1  mb-2 mt-20 pl-10" >
            <div class="col-md-12 col-lg-2">
                <label for="code-field" class="form-label">
                  <strong>Dal</strong>
                  <span class="text-danger">*</span></label>
                    <div class="input-group mb-1">
                        <input type="datetime-local" class="form-control" v-model="start"  @blur="updateDate('start', start)" />
                    </div>
                </div>
            <div class="col-md-12 col-lg-2">
              <label for="code-field" class="form-label">
                <strong>Al</strong>
                <span class="text-danger">*</span>
              </label>
              <div class="input-group mb-1">
                <input type="datetime-local" class="form-control"  v-model="end"    @blur="updateDate('end',end)" />
              </div>
             </div>
            <div class="col-md-12 col-lg-2">
              <label for="code-field" class="form-label">
                <strong>Piano temporale</strong>
              </label>
              <select class="form-select"  v-model="precision">
                <option value="month">Mensile</option>
                <option value="year">Annuale</option>
                <option value="days">Giornaliero</option>
                <option value="hours">Orario</option>

              </select>
            </div>
            <div class="col-md-12 col-lg-2">
            </div>
        </div>
       
        <g-gantt-chart
          :chart-start="chartStart"
          :chart-end="chartEnd"
          precision="month"
          bar-start="myBeginDate"
          bar-end="myEndDate"
          color-scheme="vue"
          @dblclick-bar="onMouseupBar($event.bar, $event.e, $event.datetime)"
          >
            <g-gantt-row label="My row 1" :bars="row1BarList" id="1"  @drop="onDrop($event.e, $event.datetime)"/>
            <g-gantt-row label="My row 2" :bars="row2BarList" id="2"  @drop="onDrop($event.e, $event.datetime)"/>

        </g-gantt-chart>
        <pre>{{ responseList }}</pre>
    </div>
</template>